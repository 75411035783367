
/**********************************************************
***********************************************************
***********************************************************



   SOME MORE LAYER EXAMPLES, USE ONLY WHICH YOU NEED,
   TO SAVE LOAD TIME 



***********************************************************
***********************************************************
***********************************************************/

.tp-banner-container * {transition-timing-function: none; -moz-transition-timing-function: none; -webkit-transition-timing-function: none; -o-transition-timing-function: none; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s;}


.tp-banner-container{ width:100%; position:relative; padding:0; z-index: 0;}
.tp-banner{ width:100%;	position:relative;}
.tp-banner-fullscreen-container {width:100%; position:relative; padding:0;}
.tp-caption.medium_grey {
    position:absolute;
    color:#fff;
    text-shadow:0px 2px 5px rgba(0, 0, 0, 0.5);
    font-weight:700;
    font-size:20px;
    line-height:20px;
    font-family:Arial;
    padding:2px 4px;
    margin:0px;
    border-width:0px;
    border-style:none;
    background-color:#888;
    white-space:nowrap;
}

.tp-caption.small_text {
    position:absolute;
    color:#fff;
    text-shadow:0px 2px 5px rgba(0, 0, 0, 0.5);
    font-weight:700;
    font-size:14px;
    line-height:20px;
    font-family:Arial;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.medium_text {
    position:absolute;
    color:#fff;
    text-shadow:0px 2px 5px rgba(0, 0, 0, 0.5);
    font-weight:700;
    font-size:20px;
    line-height:20px;
    font-family:Arial;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.large_text {
    position:absolute;
    color:#fff;
    text-shadow:0px 2px 5px rgba(0, 0, 0, 0.5);
    font-weight:700;
    font-size:40px;
    line-height:40px;
    font-family:Arial;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.very_large_text {
    position:absolute;
    color:#fff;
    /*text-shadow:0px 2px 5px rgba(0, 0, 0, 0.5);*/
    font-weight:800;
    font-size:70px;
    line-height:60px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:-2px;
}

.tp-caption.very_big_white {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:800;
    font-size:60px;
    line-height:60px;
    font-family:Arial;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    padding:0px 4px;
    padding-top:1px;
    background-color:#000;
}

.tp-caption.very_big_black {
    position:absolute;
    color:#000;
    text-shadow:none;
    font-weight:700;
    font-size:60px;
    line-height:60px;
    font-family:Arial;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    padding:0px 4px;
    padding-top:1px;
    background-color:#fff;
}

.tp-caption.modern_medium_fat {
    position:absolute;
    color:#000;
    text-shadow:none;
    font-weight:800;
    font-size:24px;
    line-height:20px;
    font-family:"Open Sans", sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.modern_medium_fat_white {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:800;
    font-size:24px;
    line-height:20px;
    font-family:"Open Sans", sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.modern_medium_light {
    position:absolute;
    color:#000;
    text-shadow:none;
    font-weight:300;
    font-size:24px;
    line-height:20px;
    font-family:"Open Sans", sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.modern_big_bluebg {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:800;
    font-size:30px;
    line-height:36px;
    font-family:"Open Sans", sans-serif;
    padding:3px 10px;
    margin:0px;
    border-width:0px;
    border-style:none;
    background-color:#4e5b6c;
    letter-spacing:0;
}

.tp-caption.modern_big_redbg {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:300;
    font-size:30px;
    line-height:36px;
    font-family:"Open Sans", sans-serif;
    padding:3px 10px;
    padding-top:1px;
    margin:0px;
    border-width:0px;
    border-style:none;
    background-color:#de543e;
    letter-spacing:0;
}

.tp-caption.modern_small_text_dark {
    position:absolute;
    color:#555;
    text-shadow:none;
    font-size:14px;
    line-height:22px;
    font-family:Arial;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.boxshadow {
    -moz-box-shadow:0px 0px 20px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow:0px 0px 20px rgba(0, 0, 0, 0.5);
    box-shadow:0px 0px 20px rgba(0, 0, 0, 0.5);
}

.tp-caption.black {
    color:#000;
    text-shadow:none;
}

.tp-caption.noshadow {
    text-shadow:none;
}

.tp-caption.thinheadline_dark {
    position:absolute;
    color:rgba(0,0,0,0.85);
    text-shadow:none;
    font-weight:300;
    font-size:30px;
    line-height:30px;
    font-family:"Open Sans";
    background-color:transparent;
}

.tp-caption.thintext_dark {
    position:absolute;
    color:rgba(0,0,0,0.85);
    text-shadow:none;
    font-weight:300;
    font-size:16px;
    line-height:26px;
    font-family:"Open Sans";
    background-color:transparent;
}

.tp-caption.largeblackbg {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:300;
    font-size:50px;
    line-height:70px;
    font-family:"Open Sans";
    background-color:#000;
    padding:0px 20px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    border-radius:0px;
}

.tp-caption.largepinkbg {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:300;
    font-size:50px;
    line-height:70px;
    font-family:"Open Sans";
    background-color:#db4360;
    padding:0px 20px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    border-radius:0px;
}

.tp-caption.largewhitebg {
    position:absolute;
    color:#000;
    text-shadow:none;
    font-weight:300;
    font-size:50px;
    line-height:70px;
    font-family:"Open Sans";
    background-color:#fff;
    padding:0px 20px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    border-radius:0px;
}

.tp-caption.largegreenbg {
    position:absolute;
    color:#fff;
    text-shadow:none;
    font-weight:300;
    font-size:50px;
    line-height:70px;
    font-family:"Open Sans";
    background-color:#67ae73;
    padding:0px 20px;
    -webkit-border-radius:0px;
    -moz-border-radius:0px;
    border-radius:0px;
}

.tp-caption.excerpt {
    font-size:36px;
    line-height:36px;
    font-weight:700;
    font-family:Arial;
    color:#ffffff;
    text-decoration:none;
    background-color:rgba(0, 0, 0, 1);
    text-shadow:none;
    margin:0px;
    letter-spacing:-1.5px;
    padding:1px 4px 0px 4px;
    width:150px;
    white-space:normal !important;
    height:auto;
    border-width:0px;
    border-color:rgb(255, 255, 255);
    border-style:none;
}

.tp-caption.large_bold_grey {
    font-size:60px;
    line-height:60px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(102, 102, 102);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    margin:0px;
    padding:1px 4px 0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_thin_grey {
    font-size:34px;
    line-height:30px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(102, 102, 102);
    text-decoration:none;
    background-color:transparent;
    padding:1px 4px 0px;
    text-shadow:none;
    margin:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.small_thin_grey {
    font-size:18px;
    line-height:26px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(117, 117, 117);
    text-decoration:none;
    background-color:transparent;
    padding:1px 4px 0px;
    text-shadow:none;
    margin:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.lightgrey_divider {
    text-decoration:none;
    background-color:rgba(235, 235, 235, 1);
    width:370px;
    height:3px;
    background-position:initial initial;
    background-repeat:initial initial;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.large_bold_darkblue {
    font-size:58px;
    line-height:60px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(52, 73, 94);
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_bg_darkblue {
    font-size:20px;
    line-height:20px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(52, 73, 94);
    padding:10px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_bold_red {
    font-size:24px;
    line-height:30px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(227, 58, 12);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_light_red {
    font-size:21px;
    line-height:26px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(227, 58, 12);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_bg_red {
    font-size:20px;
    line-height:20px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(227, 58, 12);
    padding:10px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_bold_orange {
    font-size:24px;
    line-height:30px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(243, 156, 18);
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_bg_orange {
    font-size:20px;
    line-height:20px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(243, 156, 18);
    padding:10px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.grassfloor {
    text-decoration:none;
    background-color:rgba(160, 179, 151, 1);
    width:4000px;
    height:150px;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.large_bold_white {
    font-size:58px;
    line-height:60px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_light_white {
    font-size:30px;
    line-height:36px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}
.tp-caption.medium_light_white-38 {
    font-size:38px;
    line-height:36px;
    font-weight:300;
    font-family:"Open Sans";
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.mediumlarge_light_white {
    font-size:34px;
    line-height:40px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.mediumlarge_light_white_center {
    font-size:34px;
    line-height:40px;
    font-weight:300;
    font-family:"Open Sans";
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    padding:0px 0px 0px 0px;
    text-align:center;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_bg_asbestos {
    font-size:20px;
    line-height:20px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(127, 140, 141);
    padding:10px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.medium_light_black {
    font-size:30px;
    line-height:36px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.large_bold_black {
    font-size:58px;
    line-height:60px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.mediumlarge_light_darkblue {
    font-size:34px;
    line-height:40px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(52, 73, 94);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.small_light_white {
    font-size:17px;
    line-height:28px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    padding:0px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.roundedimage {
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.large_bg_black {
    font-size:40px;
    line-height:40px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(0, 0, 0);
    padding:10px 20px 15px;
    border-width:0px;
    border-color:rgb(255, 214, 88);
    border-style:none;
}

.tp-caption.mediumwhitebg {
    font-size:30px;
    line-height:30px;
    font-weight:300;
    font-family:"Open Sans";
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:rgb(255, 255, 255);
    padding:5px 15px 10px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.large_bold_white_25 {
    font-size:55px;
    line-height:65px;
    font-weight:700;
    font-family:"Open Sans";
    color:#fff;
    text-decoration:none;
    background-color:transparent;
    text-align:center;
    text-shadow:#000 0px 5px 10px;
    border-width:0px;
    border-color:rgb(255, 255, 255);
    border-style:none;
}

.tp-caption.medium_text_shadow {
    font-size:25px;
    line-height:25px;
    font-weight:600;
    font-family:"Open Sans";
    color:#fff;
    text-decoration:none;
    background-color:transparent;
    text-align:center;
    text-shadow:#000 0px 5px 10px;
    border-width:0px;
    border-color:rgb(255, 255, 255);
    border-style:none;
}

.tp-caption.black_heavy_60 {
    font-size:60px;
    line-height:60px;
    font-weight:900;
    font-family: Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.white_heavy_40 {
    font-size:40px;
    line-height:40px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.grey_heavy_72 {
    font-size:72px;
    line-height:72px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(213, 210, 210);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.grey_regular_18 {
    font-size:18px;
    line-height:26px;
    font-family:"Open Sans";
    color:rgb(119, 119, 119);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_thin_34 {
    font-size:35px;
    line-height:35px;
    font-weight:100;
    font-family:Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.arrowicon {
    line-height:1px;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.light_heavy_60 {
    font-size:60px;
    line-height:60px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_bold_40 {
    font-size:40px;
    line-height:40px;
    font-weight:800;
    font-family:Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}
.tp-caption.black_bold_32 {
    font-size:34px;
    line-height:34px;
    font-weight:600;
    font-family: "Open Sans",sans-serif;
    text-transform: uppercase;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    letter-spacing: 6px;
}

.tp-caption.light_heavy_70 {
    font-size:70px;
    line-height:70px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_heavy_70 {
    font-size:70px;
    line-height:70px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_bold_bg_20 {
    font-size:20px;
    line-height:20px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(0, 0, 0);
    padding:5px 8px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.greenbox30 {
    line-height:30px;
    text-decoration:none;
    background-color:rgb(134, 181, 103);
    padding:0px 14px;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.blue_heavy_60 {
    font-size:60px;
    line-height:60px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(49, 165, 203);
    padding:3px 10px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.green_bold_bg_20 {
    font-size:20px;
    line-height:20px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(134, 181, 103);
    padding:5px 8px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.whitecircle_600px {
    line-height:1px;
    width:800px;
    height:800px;
    text-decoration:none;
    background:linear-gradient(to bottom,  rgba(238,238,238,1) 0%,rgba(255,255,255,1) 100%);
    filter:progid;
    background-color:transparent;
    border-radius:400px 400px 400px 400px;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.fullrounded {
    border-radius:400px 400px 400px 400px;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.light_heavy_40 {
    font-size:40px;
    line-height:40px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.white_thin_34 {
    font-size:35px;
    line-height:35px;
    font-weight:200;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.fullbg_gradient {
    width:100%;
    height:100%;
    text-decoration:none;
    background-color:#490202;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}


.tp-caption.light_medium_30 {
    font-size:30px;
    line-height:30px;
    font-weight:100;
    font-family: "Oswald",sans-serif;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    letter-spacing: 6px;
}

.tp-caption.red_bold_bg_20 {
    font-size:20px;
    line-height:20px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(224, 51, 0);
    padding:5px 8px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}
.tp-caption.orange_bold_bg_20 {
    text-decoration:none;
    background-color:rgb(236, 131, 0);
    padding:2px 8px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.blue_bold_bg_20 {
    font-size:20px;
    line-height:20px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(53, 152, 220);
    padding:5px 8px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.white_bold_bg_20 {
    font-size:20px;
    line-height:20px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:rgb(255, 255, 255);
    padding:5px 8px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.white_heavy_70 {
    font-size:70px;
    line-height:70px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.light_medium_30_shadowed {
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 8px;
    line-height: 80px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}
.tp-caption.light_medium_30_shadowed-black {
    color:#000000 !important;
}

.tp-caption.light_medium_28_shadowed {
    font-family: "Oswald",sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 50px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    letter-spacing: 4px;
}
.tp-caption.light_medium_40_shadowed {
    font-family: "Oswald",sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 50px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    letter-spacing: 4px;
}

.tp-caption.light_small_70_shadowed {
    font-family: "Open Sans",sans-serif;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 7px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}
.tp-caption.light_heavy_70_shadowed {
    font-family: "Open Sans",sans-serif;
    font-size: 35px;
    font-weight: 600;
    letter-spacing: 7px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.blackboxed_heavy {
    font-size:70px;
    line-height:70px;
    font-weight:800;
    font-family:"Open Sans";
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(0, 0, 0);
    padding:5px 20px;
    text-shadow:rgba(0, 0, 0, 0.14902) 0px 0px 7px;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.bignumbers_white {
    color:#ffffff;
    background-color:rgba(0, 0, 0, 0);
    font-size:84px;
    line-height:84px;
    font-weight:800;
    font-family:Raleway;
    text-decoration:none;
    padding:0px 0px 0px 0px;
    text-shadow:rgba(0, 0, 0, 0.247059) 0px 0px 7px;
    border-width:0px;
    border-color:rgb(255, 255, 255);
    border-style:none solid none none;
}

.tp-caption.whiteline_long {
    line-height:1px;
    min-width:660px;
    background-color:transparent;
    text-decoration:none;
    border-width:2px 0px 0px 0px;
    border-color:rgb(255, 255, 255) rgb(34, 34, 34) rgb(34, 34, 34) rgb(34, 34, 34);
    border-style:solid none none none;
}

.tp-caption.light_medium_20_shadowed {
    font-size:20px;
    line-height:30px;
    font-weight:700;
    font-family:Raleway;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    text-shadow:0px 0px 7px rgba(0, 0, 0, 0.25);
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.fullgradient_overlay {
    background:linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    filter:progid;
    width:100%;
    height:100%;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.light_medium_20 {
    font-size:20px;
    line-height:30px;
    font-weight:700;
    font-family: "Oswald",sans-serif;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}


.tp-caption.reddishbg_heavy_70 {
    font-size:70px;
    line-height:70px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgba(100, 1, 24, 0.8);
    padding:50px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.borderbox_725x130 {
    min-width:725px;
    min-height:130px;
    background-color:transparent;
    text-decoration:none;
    border-width:2px;
    border-color:rgb(255, 255, 255);
    border-style:solid;
}

.tp-caption.light_heavy_34 {
    font-size:34px;
    line-height:34px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_thin_30 {
    font-size:30px;
    line-height:30px;
    font-weight:100;
    font-family:Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_thin_whitebg_30 {
    font-size:30px;
    line-height:30px;
    font-weight:300;
    font-family:Raleway;
    color:rgb(0, 0, 0);
    text-decoration:none;
    background-color:rgb(255, 255, 255);
    padding:5px 10px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.white_heavy_60 {
    font-size:60px;
    line-height:60px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.black_thin_blackbg_30 {
    font-size:30px;
    line-height:30px;
    font-weight:300;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:rgb(0, 0, 0);
    padding:5px 10px;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.light_thin_60 {
    font-size:60px;
    line-height:60px;
    font-weight:100;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    background-color:transparent;
    text-shadow:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.greenbgfull {
    background-color:#85b85f;
    width:100%;
    height:100%;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.bluebgfull {
    text-decoration:none;
    width:100%;
    height:100%;
    background-color:rgb(61, 164, 207);
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.blackbgfull {
    text-decoration:none;
    width:100%;
    height:100%;
    background-color:rgba(0, 0, 0, 0.247059);
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.wave_repeat1 {
    width:100%;
    height:600px;
    background-repeat:repeat-x;
    background-color:transparent;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.wavebg2 {
    width:200%;
    height:300px;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.wavebg1 {
    width:200%;
    height:300px;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.wavebg3 {
    width:200%;
    height:300px;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.wavebg4 {
    width:200%;
    height:300px;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.greenishbg_heavy_70 {
    font-size:70px;
    line-height:70px;
    font-weight:900;
    font-family:Raleway;
    color:rgb(255, 255, 255);
    text-decoration:none;
    padding:50px;
    text-shadow:none;
    background-color:rgba(40, 67, 62, 0.8);
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.wavebg5 {
    width:200%;
    height:300px;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}

.tp-caption.deepblue_sea {
    width:100%;
    height:1000px;
    background-color:rgba(30, 46, 59, 1);
    text-decoration:none;
    border-width:0px;
    border-color:rgb(34, 34, 34);
    border-style:none;
}


.tp-caption a {
    color:#ff7302;
    text-shadow:none;
    -webkit-transition:all 0.2s ease-out;
    -moz-transition:all 0.2s ease-out;
    -o-transition:all 0.2s ease-out;
    -ms-transition:all 0.2s ease-out;
}

.tp-caption a:hover {
    color:#ffa902;
}

.largeredbtn {  
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 3px;
    color: #fff !important;
    text-decoration: none;
    padding: 6px 25px;
    background:transparent;
    border: 2px solid #fff;
    text-transform: uppercase;
    display: inline-block;

}
.largeredbtn-black .largeredbtn {  
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 3px;
    color: #000 !important;
    text-decoration: none;
    padding: 6px 25px;
    background:transparent;
    border: 2px solid #000;
    text-transform: uppercase;
    display: inline-block;

}

.largeredbtn:hover {
    background:#ffffff;
    color: #000000 !important;
     
}

.fullrounded img {
    -webkit-border-radius: 400px;
    -moz-border-radius: 400px;
    border-radius: 400px; 
}
/**********************************************************
custom css
***********************************************************/

.tp-caption.light_medium_30_black-bg {
    font-family: "Oswald",sans-serif;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 6px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:#000;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    padding: 10px 16px;
}
.tp-caption.light_medium_40_black-bg {
    font-family: "Oswald",sans-serif;
    font-size: 30px;
    font-weight: 100;
    letter-spacing: 6px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    background-color:#000;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    padding: 16px 22px;
}
.tp-caption.light_medium_40_black_text {
    font-family: "Oswald",sans-serif;
    font-size: 26px;
    font-weight: 100;
    letter-spacing: 6px;
    text-transform: uppercase;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}
.tp-caption.light_medium_120 {
    font-family: "Oswald",sans-serif;
    font-size: 120px;
    font-weight: 500;
    letter-spacing: normal;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 120px;
    text-shadow:5px 5px rgba(178, 125, 39, 1);
}

.tp-caption.light_medium_100 {
    font-family: "Oswald",sans-serif;
    font-size: 100px;
    font-weight: 500;
    letter-spacing: normal;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 120px;
    text-shadow:5px 5px rgba(52, 184, 215, 1);
}

.tp-caption.light_medium_110_black_text {
    font-family: "Oswald",sans-serif;
    font-size: 110px;
    font-weight: 500;
    letter-spacing: normal;
    text-transform: uppercase;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 120px;
}
.tp-caption.light_text_big {
    font-family: "Oswald",sans-serif;
    font-size: 174px;
    font-weight: 300;
    letter-spacing: normal;
    text-transform: uppercase;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 120px;
}
.tp-caption.normal_text_big2 {
    font-family: "Oswald",sans-serif;
    font-size: 192px;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: uppercase;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 120px;
}
.tp-caption.light_medium_70_black_text {
    font-family: "Oswald",sans-serif;
    font-size: 70px;
    font-weight: 500;
    letter-spacing: normal;
    text-transform: uppercase;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 120px;
}
.tp-caption.light_medium_65 {
    font-family: "Oswald",sans-serif;
    font-size: 70px;
    font-weight: 300;
    letter-spacing: normal;
    text-transform: uppercase;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 90px;
}

.tp-caption.light_medium_40_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 5px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 44px;
}

.tp-caption.light_medium_17_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: normal;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: normal;
}

.tp-caption.light_medium_16_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 4px;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 22px;
}
.tp-caption.light_medium_16_black_text {
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: normal;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 22px;
}
.tp-caption.light_medium_60_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 32px;
    font-weight: 100;
    letter-spacing: 4px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 40px;
}

.tp-caption.light_medium_40_text {
    font-family: "Open Sans",sans-serif;
    font-size: 40px;
    font-weight: 100;
    letter-spacing: 4px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 40px;
}

.tp-caption.light_medium_45_text {
    font-family: "Open Sans",sans-serif;
    font-size: 45px;
    font-weight: 300;
    letter-spacing: normal;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.light_medium_20_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 4px;
    text-transform: uppercase;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 30px;
}
.tp-caption.regular_medium_20{
    font-family: "Open Sans",sans-serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: normal;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 24px;;
}

.tp-caption.Semi_Bold_medium_70_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 70px;
    font-weight: 600;
    letter-spacing: normal;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}
.tp-caption.extra_Bold_medium_70_black_text {
    font-family: "Open Sans",sans-serif;
    font-size: 70px;
    font-weight: 800;
    letter-spacing: normal;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    text-transform: uppercase;
}
.tp-caption.Semi_Bold_medium_60_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 60px;
    font-weight: 600;
    letter-spacing: normal;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
}

.tp-caption.light_medium_20_black_text {
    font-family: "Open Sans",sans-serif;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: normal;
    text-transform: uppercase;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 30px;
}

.tp-caption.light_regular_13_black_text {
    font-family: "Open Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: normal;
    color:#000000;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 18px;
}
.tp-caption.light_regular_13_white_text {
    font-family: "Open Sans",sans-serif;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: normal;
    color:#ffffff;
    text-decoration:none;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    line-height: 18px;
}

.tp-caption.light_medium_big {
    font-size:30px;
    line-height:30px;
    font-weight:500;
    font-family: "Oswald",sans-serif;
    color:#ffffff;
    text-decoration:none;
    background-color:transparent;
    border-width:0px;
    border-color:rgb(0, 0, 0);
    border-style:none;
    letter-spacing: 6px;
}

.tp-caption.very_large_text_90 {
    position:absolute;
    color:#fff;
    /*text-shadow:0px 5px 5px rgba(0, 0, 0, 0.5);*/
    font-weight:900;
    font-size:90px;
    line-height:60px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:-2px;
}

.tp-caption.photography-very_large_text_90 {
    position:absolute;
    color:#fff;
    /*text-shadow:0px 5px 5px rgba(0, 0, 0, 0.5);*/
    font-weight:700;
    font-size:90px;
    line-height:60px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:50px;
}

.tp-caption.very_large_text_32 {
    position:absolute;
    color:#fff;
    /*text-shadow:0px 5px 5px rgba(0, 0, 0, 0.5);*/
    font-weight:700;
    font-size:32px;
    line-height:60px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:-2px;
}

.tp-caption.very_large_text_26 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:26px;
    line-height:30px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:normal;
}

.tp-caption.regular_small_text_18 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:18px;
    line-height:30px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:normal;
}

.tp-caption.very_large_text_30_black {
    position:absolute;
    color:#000;
    /*text-shadow:0px 5px 5px rgba(0, 0, 0, 0.5);*/
    font-weight:100;
    font-size:30px;
    line-height:36px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
}

.tp-caption.very_large_text_125 {
    position:absolute;
    color:#fff;
    /*text-shadow:0px 5px 5px rgba(0, 0, 0, 0.5);*/
    font-weight:900;
    font-size:125px;
    line-height:60px;
    font-family: "Open Sans",sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:-2px;
}
.tp-caption.regular_text_40 {
    position:absolute;
    color:#000;
    font-weight:400;
    font-size:40px;
    line-height:60px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    text-transform: uppercase;
}
.tp-caption.regular_text_50_blod {
    position:absolute;
    color:#fff;
    font-weight:600;
    font-size:50px;
    line-height:62px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
    text-transform: uppercase;
}
.tp-caption.regular_text_50 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:40px;
    line-height:52px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
    text-transform: uppercase;
}
.tp-caption.regular_text_20 {
    position:absolute;
    color:#000;
    font-weight:400;
    font-size:20px;
    line-height:60px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    text-transform: uppercase;
}
.tp-caption.regular_text_18 {
    position:absolute;
    color:#000;
    font-weight:400;
    font-size:18px;
    line-height:60px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}
.tp-caption.regular_text_26 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:26px;
    line-height:34px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:normal;
    text-transform: uppercase;
}

.tp-caption.regular_text_40_white {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:40px;
    line-height:60px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    text-transform: uppercase;
}

.tp-caption.regular_text_30 {
    position:absolute;
    color:#000;
    font-weight:400;
    font-size:30px;
    line-height:60px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    text-transform: uppercase;
}
.tp-caption.regular_text_100 {
    position:absolute;
    color:#000000;
    font-weight:400;
    font-size:100px;
    line-height:90px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    text-transform: uppercase;
}
.tp-caption.regular_text_290 {
    position:absolute;
    color:#e6af2a;
    font-weight:400;
    font-size:290px;
    line-height:90px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
}
.tp-caption.regular_text_80 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:80px;
    line-height:80px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
}

.tp-caption.regular_text_60 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:60px;
    line-height:60px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
}
.tp-caption.regular_text_70 {
    position:absolute;
    color:#fff;
    font-weight:400;
    font-size:70px;
    line-height:70px;
    font-family: 'Playfair Display', serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing: normal;
}

.tp-caption.fashion_text_70 {
    position:absolute;
    color:#000000;
    font-weight:400;
    font-size:70px;
    line-height:60px;
    font-family: 'Great Vibes', cursive;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    
}
.tp-caption.fashion_text_120 {
    position:absolute;
    color:#000000;
    font-weight:400;
    font-size:120px;
    line-height:60px;
    font-family: 'Great Vibes', cursive;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    
}
.tp-caption.fashion_text_100 {
    position:absolute;
    color:#000000;
    font-weight:400;
    font-size:100px;
    line-height:70px;
    font-family: 'Great Vibes', cursive;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    letter-spacing:2px;
    
}
.tp-caption.roboto_condensed_regular_16 {
    position:absolute;
    color:#000000;
    font-weight:400;
    font-size:16px;
    font-family: 'Roboto Condensed', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
}

.tp-caption.roboto_condensed_regular_20 {
    position:absolute;
    color:#ffffff;
    font-weight:400;
    font-size:20px;
    font-family: 'Roboto Condensed', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;   
}
.tp-caption.roboto_condensed_blod_40 {
    position:absolute;
    color:#ffffff;
    font-weight:700;
    font-size:40px;
    font-family: 'Roboto Condensed', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;   
    line-height: 48px;
}
.tp-caption.roboto_condensed_blod_60 {
    position:absolute;
    color:#ffffff;
    font-weight:700;
    font-size:60px;
    font-family: 'Roboto Condensed', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;   
    line-height: 60px;
}
.tp-caption.roboto_condensed_Light_22 {
    position:absolute;
    color:#ffffff;
    font-weight:300;
    font-size:22px;
    font-family: 'Roboto Condensed', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;   
    line-height: 60px;
}

.tp-caption.roboto_condensed_blod_26 {
    position:absolute;
    color:#000000;
    font-weight:700;
    font-size:26px;
    font-family: 'Roboto Condensed', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;   
    line-height: 60px;
}

.tp-caption.roboto_regular_20 {
    position:absolute;
    color:#ffffff;
    font-weight:400;
    font-size:20px;
    font-family: 'Roboto', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;   
    line-height: 24px;
}

.tp-caption.roboto_regular_12 {
    position:absolute;
    color:#000000;
    font-weight:400;
    font-size:12px;
    font-family: 'Roboto', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    line-height: 18px;
}
.tp-caption.roboto_regular_15 {
    position:absolute;
    color:#ffffff;
    font-weight:300;
    font-size:15px;
    font-family: 'Roboto', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    line-height: 20px;
}
.tp-caption.icon-font {
    position:absolute;
    color:#ffffff;
    font-weight:300;
    font-size:16px;
    font-family: 'Roboto', sans-serif;
    margin:0px;
    border-width:0px;
    border-style:none;
    white-space:nowrap;
    line-height: 20px;
    letter-spacing: 2px;
}
