body {
	background: #ddd;	
}

.logo-large {
	height: 100px;
	
	.logo {
		max-height: 50px;
	}
}

.bg-slider {
	background: rgba(51, 51, 51, 1);	
}
.sticky-nav, .nav-white {
	&.bg-white {
		//background: #782926 !important;
		background: rgba(51, 51, 51, 1) !important;
		/*
		.logo-light {
			display: none;
			opacity: 0;
		}
		.logo-dark {
			opacity: 1;
			height: 55px;
			vertical-align: middle;
			display: table-cell;
		}
		*/
	}
	
	&.shrink-nav {
		background: rgba(51, 51, 51, 1) !important;
		
		.logo-light {
			display: table-cell;
			opacity: 1;
		}
		.logo-dark {
			display: none;
			opacity: 0;
		}
	}
	
	&.bg-transparent {
		background: rgba(51, 51, 51, 0.5) !important;
	}
	
	&.navbar-default, &.shrink-nav {
		.navbar-nav li {
			a {
				color: white;

				&:hover {
					border-bottom-color: white;	
				}
			}
		}
	}
}

#projects {
	.grid {
		figure {
			background-color: rgba(51, 51, 51, 1);	
		}
		figure:hover {
			.gallery-img {
				img {
					opacity: 1;
				}
			}
		}
		figcaption {
			display: block;
			opacity: 1;
			z-index: 2;
			height: 40px;
			bottom: 0 !important;
			background: rgba(51, 51, 51, 0.5) !important;
			text-align: left;

			p {
				margin-top: 10px;
				margin-left: 15px;
				margin-right: 15px;
			}

			&:hover {
				cursor: url(http://localhost:3000/assets/images/icon-zoom-white.png), pointer;	
			}
		}
		
		figcaption {
			display: block;
			opacity: 1;
			z-index: 2;
			height: 40px;
			bottom: 0 !important;
			background: rgba(51, 51, 51, 0.5) !important;
			text-align: center;
			
			p {
				margin-top: 10px;
				color: white;
			}

			&:hover {
				cursor: url(http://localhost:3000/assets/images/icon-zoom-white.png), pointer;	
			}
		}
	}
}

#project {
	.project-details {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 350px;
		z-index: 2;
		
		@media (max-width: 991px) {
			left: 50%;
			margin-left: -175px;
			bottom: 80px;
		}
		
		@media (max-width: 480px) {
			bottom: 50px;
		}
	}

	.project-overlay {
		background: rgba(51, 51, 51, 0.5);
		padding: 20px;
		color: white;
		text-align: left;

		h1, h2, p {
			color: white;	
		}
		
		h1 {
			font-size: 16px;
			line-height: 130%;
		}
		
		h2 {
			font-size: 14px;
			border-bottom: 1px solid #aeaeae;
			margin-bottom: 10px;
			padding-bottom: 5px;
		}
		
		li {
			border: none;
			line-height: 130%;
			padding-top: 0px;
			margin: 0;
			color: #aeaeae;
		}
	}
}

html {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100%;
}

#contact {
	.btn {
		margin-top: 0 !important;
		margin-bottom: 20px !important;
		margin-right: 0;
	}
	
	.item, item:hover {
		cursor: default
	}
}

#team {
	.btn {
		margin-top: 10px;	
	}
	
	.team-member {
		img {
			margin-top: -50px;
			margin-bottom: -50px;
		}
		
		&:hover {
			img {
				opacity: 0.75;
			}
		}
		figure {
			background: #ddd !important;
			width: 100.1%;
		}
	}
}

#team-member {
	.team-member {
		img {
			margin-top: -50px;
			margin-bottom: -50px;
		}
		
		&:hover {
			img {
				opacity: 1;
			}
		}
	}
	
	h2 {
		margin-bottom: 20px;	
	}
	
	@media (max-width: 991px) {
		h1 {
			font-size: 22px;
			line-height: 150%;
		}
		h2 {
			font-size: 16px;
			line-height: 130%;
		}
	}
	
	@media (max-width: 767px) {
		#bio {
			margin-top: 50px;	
		}
		h1 {
			margin-top: 20px;	
		}
	}
}

.client-logo-inner {
	background: #fff;	
	
	img {
		opacity: 1;	
	}
}

.owl-item {
	cursor: default !important;
}

figure:hover img {
	cursor: pointer;
}
figcaption {
	cursor: default;
	
	&:hover {
		cursor: pointer !important;
	}
}

section {
	padding: 120px 0 0 0;	
}

#about {
	section {
		padding-top: 50px;	
		
		p {
			color: black;
		}
		
		ul {
			margin-top: 10px;
			margin-bottom: 20px;
			list-style: square;
			list-style-position: inside;
			color: black;

			li {
				margin-left: 20px;	
			}
		}

		a {
			color: #7c2529;
			font-weight: bold;
		}
		
		h3 {
			color: #7c2529;	
		}
	}
	.cover-background {
		height: 300px;
		padding: 0;
		margin-top: 50px;
		
		h1 {
			margin-top: 140px;	
		}
		
		@media (max-width: 767px) {
			height: 150px;
			
			h1 {
				margin-top: 65px;	
			}
		}
	}
}

.nav-tabs-light a {
	color: #7c2529;	
}

.red-text {
	color: #7c2529 !important;
}

#clients, #team {
	.text-large {
		margin-top: 20px;
		margin-bottom: 50px;
	}
}

//.owl-nav { position: static; }
//.owl-dots { bottom: 30px; display: block; left: 0; position: absolute; text-align: center; width: 100%; z-index: 100 !important; }
//.owl-dot { display: inline-block; padding: 6px 5px; }
//.owl-dot span { background: none repeat scroll 0 0 rgba(255, 255, 255, 0.7); border-radius: 4px; box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1); display: block; height: 7px; transition: all 0.27s cubic-bezier(0, 0, 0.58, 1) 0s; width: 7px; }
//.owl-dot:hover span { background: none repeat scroll 0 0 rgba(255, 255, 255, 1); }
//.owl-dot.active span { background: none repeat scroll 0 0 transparent; border: 1px solid rgba(255, 255, 255, 0.8); transform: scale(1.33333); }
//
///* square pagination */
//.square-pagination .owl-dot span { width:30px; border-radius: inherit; height:3px; background: rgba(255,255,255,.5)}
//.square-pagination .owl-dot.active span {border:none !important; transform:none!important; background:#fff !important}
//
//.owl-carousel {
//	max-height: 10px;
//	overflow: hidden;
//}
//
//.owl-loaded {
//	max-height: none;
//}